import React, { useCallback, useEffect, useState } from 'react';
import { ErrorBoundary } from '@components/Shared';
import { useSelector, useDispatch } from 'react-redux';
import ReactApexChart from 'react-apexcharts';
import { ApexOptions } from 'apexcharts';
import "./Prediction.css";
import Button from '@mui/material/Button';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';
import { GetAccountGetAccountDetailsParams, GetDataPredictionGetPredictionParams, GetDataPredictionGetPredictionStatusParams, GetExchangeItemGetExchangeItemDetailsParams, PostDataPredictionCreatePredictionParams } from '../../model';
import { useGetExchangeItemGetExchangeItemDetails, useGetExchangeItemGetExchangeItems, useGetExchangeItemGetIndicators } from '../../client/exchange-item/exchange-item';
import { ExchangeItemData } from './ExchangeItemData';
import { AxiosResponse } from 'axios';
import qs from 'qs';
import { CoveredItemData, Indicator } from './CoveredItemData';
import dayjs from 'dayjs';
import { FormControl, InputLabel, MenuItem, Select, TextField } from '@mui/material';
import { getDataPredictionGetPrediction, getDataPredictionGetPredictionStatus, postDataPredictionCreatePrediction, useGetDataPredictionGetPrediction, usePostDataPredictionCreatePrediction } from '../../client/data-prediction/data-prediction';
import { DataPredictionResult } from './DataPredictionResult';
import { AccountDetails } from '../../client/account/AccountDetails';
import { useGetAccountGetAccountDetails } from '../../client/account';
import { useUserContext } from '@components/Shared/UserContext';
import { Link, useNavigate  } from "react-router-dom";

const customParamsSerializer = (params: any) => {
    return qs.stringify(params, { arrayFormat: 'repeat' });
};

const Prediction = () => {
    const [chartData, setChartData] = useState<ApexOptions>({
        chart: {
            type: 'candlestick',
            toolbar: {
                show: false
            }
        },
        series: [{
            name: 'Price',
            data: []
        }],
        xaxis: {
            type: 'category',
            categories: [],
        }
    });

    const [indicatorsChartData, setIndicatorsChartData] = useState<ApexOptions>({
        chart: {
            type: 'line',
            toolbar: {
                show: false
            }
        },
        series: [],
        xaxis: {
            type: 'category',
            categories: [],
        }
    });

    const [mixedChartData, setMixedChartData] = useState<ApexOptions>({
        chart: {
            type: 'line', // mixed type can be line, area, bar, etc.
            toolbar: {
                show: false
            }
        },
        series: [],
        xaxis: {
            type: 'category',
            categories: [],
        }
    });

    const [shouldFetch, setShouldFetch] = useState(true);
    const [isLoadingPrediction, setisLoadingPrediction] = useState(false);
    const [selectedTicker, setSelectedTicker] = useState<string | null>("BTC-USD");
    const [selectedCategory, setSelectedCategory] = useState<string | null>("crypto");
    const [selectedInterval, setSelectedInterval] = useState<string>('1d');
    const [selectedStartDate, setSelectedStartDate] = useState<string>("2024-01-01");
    const [expandedPanel, setExpandedPanel] = useState<string | false>(false);
    const [activeInterval, setActiveInterval] = useState<string | null>('ytd');
    const [selectedIndicators, setSelectedIndicators] = useState<string[]>([]);

    const [predictionId, setPredictionId] = useState<string | null>(null);
    const [predictionResult, setPredictionResult] = useState<string>('');
    const [predictionStatus, setPredictionStatus] = useState<string>('1');
    const getRandomMultiplier = (min: number, max: number) => {
        return Math.floor(Math.random() * (max - min + 1)) + min;
    };
    const randomMultiplier = getRandomMultiplier(11, 16);
    const predictionEstimatedTime = parseInt(predictionStatus, 10) * randomMultiplier;

    const isValidStatus = !isNaN(parseInt(predictionStatus, 10)) && parseInt(predictionStatus, 10) !== -1;
    const [intervalId, setIntervalId] = useState<NodeJS.Timeout | null>(null);

    const [optimizer, setOptimizer] = useState<string>('adam');
    const [epochs, setEpochs] = useState<number>(10);
    const [inputUnits, setInputUnits] = useState<number>(32); 
    const [batchSize, setBatchSize] = useState<number>(2);

    const { data: exchangeItemsData, error: exchangeItemsError } = useGetExchangeItemGetExchangeItems<AxiosResponse<CoveredItemData[]>>();
    const { data: indicatorsData, error: indicatorsError } = useGetExchangeItemGetIndicators<AxiosResponse<Indicator[]>>();

    const username = localStorage.getItem("username")?.replace(/"/g, '');
    const password = localStorage.getItem("password")?.replace(/"/g, '');
    const getProfileDataRequest: GetAccountGetAccountDetailsParams = {
        userEmail: username,
        password: password
    };
    const { data: profileData, error: profileError } = useGetAccountGetAccountDetails<AxiosResponse<AccountDetails>>(getProfileDataRequest, {
        query: {
            retry: false,
        },
        axios: {
            paramsSerializer: customParamsSerializer
        }
      });

    const { currentUser, setCurrentUserContext } = useUserContext();

    const getItemDataRequest: GetExchangeItemGetExchangeItemDetailsParams = {
        startDate: selectedStartDate || "2024-01-01",
        ticker: selectedTicker || "btc-usd",
        type: selectedCategory || "Crypto",
        indicators: selectedIndicators,
        interval: selectedInterval,
    };

    // todo
    const { data, error, refetch } = useGetExchangeItemGetExchangeItemDetails<AxiosResponse<ExchangeItemData[]>>(getItemDataRequest, {
        query: {
            enabled: shouldFetch,
            retry: false,
        },
        axios: {
            paramsSerializer: customParamsSerializer
        }
    });

    const [lastPrice, setLastPrice] = useState(0);

    const handleFeedData = async () => {
        setShouldFetch(true);
        try {
            setisLoadingPrediction(false);
            setPredictionResult('');
            const result = await refetch();
            if (result) {
                var newLastPrice = result.data.data[result.data.data.length - 1]?.ClosePrice;
                setLastPrice(newLastPrice);
                updateChart(result.data.data);
                updateIndicatorsChart(result.data.data);
                // updateMixedChart(result.data.data);
            }
        } catch (err) {
            console.error('Error fetching data:', err);
        } finally {
            setShouldFetch(false);
        }
    };

    const updateChart = (data: ExchangeItemData[]) => {
        if (data.length === 0) {
            setChartData({
                ...chartData,
                series: [{
                    data: []
                }],
                noData: {
                    text: 'No data available',
                    align: 'center',
                    verticalAlign: 'middle',
                    offsetX: 0,
                    offsetY: 0,
                    style: {
                        fontSize: '14px',
                        color: '#666'
                    }
                }
            });
        } else {
            let formattedData = [];
            let labelIndexCount = 0;
            if (activeInterval == '1d' || activeInterval == '5d') {
                formattedData = data.map(item => ({
                    x: dayjs(item.Date).format('DD.MM HH:mm'),
                    y: [item.OpenPrice, item.High, item.Low, item.ClosePrice]
                }));
                labelIndexCount = Math.round(data.length / 6);
            } else {
                formattedData = data.map(item => ({
                    x: dayjs(item.Date).format('DD.MM.YYYY'),
                    y: [item.OpenPrice, item.High, item.Low, item.ClosePrice]
                }));
                labelIndexCount = Math.round(data.length / 7);
            }
            let labelIndex = 0;
            setChartData({
                chart: {
                    type: 'candlestick',
                    toolbar: {
                        show: false
                    }
                },
                tooltip: {
                    enabled: true,
                },
                series: [{
                    name: 'Price',
                    data: formattedData
                }],
                xaxis: {
                    type: 'category',
                    labels: {
                        offsetY: -10,
                        formatter: function(val) {
                            labelIndex++;
                            if (labelIndex == labelIndexCount) {
                                labelIndex = 0;
                                return val;
                            } else {
                                return '';
                            }
                        }
                    }
                },
                yaxis: {
                    labels: {
                        offsetX: -5,
                        formatter: (value) => value.toFixed(2)
                    },
                    tooltip: {
                        enabled: true
                    }
                }
            });
        }
    };

    const updateIndicatorsChart = (data: ExchangeItemData[]) => {
        if (data.length === 0) {
            setIndicatorsChartData({
                ...indicatorsChartData,
                series: [],
                noData: {
                    text: 'No data available',
                    align: 'center',
                    verticalAlign: 'middle',
                    offsetX: 0,
                    offsetY: 0,
                    style: {
                        fontSize: '14px',
                        color: '#666'
                    }
                }
            });
        } else {          
            const series = selectedIndicators.map(indicatorKey => {
                const indicatorData = data.map(item => item[indicatorKey]);
                const min = Math.min(...indicatorData);
                const max = Math.max(...indicatorData);
                const normalizedData = indicatorData.map(value => (value - min) / (max - min));
                return {
                    name: indicatorKey,
                    data: normalizedData
                };
            });
            setIndicatorsChartData({
                chart: {
                    type: 'line',
                    toolbar: {
                        show: false
                    }
                },
                series: series,
                xaxis: {
                    type: 'category',
                    //categories: categories,
                    labels: {
                        show: false,
                        offsetY: -0,
                    },
                },
                yaxis: {
                    labels: {
                        offsetX: -5,
                        formatter: (value) => value.toFixed(2)
                    },
                    tooltip: {
                        enabled: true
                    }
                }
            });
        }
    };

    const updateMixedChart = (data: ExchangeItemData[]) => {
        if (data.length === 0) {
            setMixedChartData({
                ...mixedChartData,
                series: [],
                noData: {
                    text: 'No data available',
                    align: 'center',
                    verticalAlign: 'middle',
                    offsetX: 0,
                    offsetY: 0,
                    style: {
                        fontSize: '14px',
                        color: '#666'
                    }
                }
            });
        } else {
            const categories = data.map(item => dayjs(item.Date).format('DD.MM HH:mm'));
            const priceSeries = {
                name: 'Price',
                type: 'line',
                data: data.map(item => ({
                    x: dayjs(item.Date).format('DD.MM HH:mm'),
                    y: item.ClosePrice //[item.OpenPrice, item.High, item.Low, item.ClosePrice]
                }))
            };

            const indicatorSeries = selectedIndicators.map(indicatorKey => {
                const indicatorData = data.map(item => item[indicatorKey]);
                const min = Math.min(...indicatorData);
                const max = Math.max(...indicatorData);
                const normalizedData = indicatorData.map(value => (value - min) / (max - min));
                return {
                    name: indicatorKey,
                    type: 'line',
                    data: normalizedData
                };
            });

            const mixedData = [priceSeries, ...indicatorSeries];

            console.log('Categories:', categories);
            console.log('Price Series:', priceSeries);
            console.log('Indicator Series:', indicatorSeries);
            console.log('mixedData:', mixedData);

            setMixedChartData({
                chart: {
                    type: 'line',
                    toolbar: {
                        show: false
                    }
                },
                series: indicatorSeries, //[priceSeries, ...indicatorSeries], //
                xaxis: {
                    type: 'category',
                    categories: categories
                },
                yaxis: {
                    // labels: {
                    //     offsetX: -15,
                    //     formatter: (value) => value.toFixed(2)
                    // },
                    // tooltip: {
                    //     enabled: true
                    // }
                }
            });
            console.log('setMixedChartData after')
        }
    };

    const handleTickerClick = (ticker: string, category: string) => {
        setSelectedTicker(ticker);
        setSelectedCategory(category);
        setExpandedPanel(false);
        setShouldFetch(true);
    };

    const handleIntervalClick = (interval: string) => {
        setActiveInterval(interval);
    
        let startDate: string;
    
        switch (interval) {
            case '1d':
                setSelectedInterval('5m');
                startDate = dayjs().subtract(1, 'day').format('YYYY-MM-DD');
                break;
            case '5d':
                setSelectedInterval('1h');
                startDate = dayjs().subtract(5, 'day').format('YYYY-MM-DD');
                break;
            case '1m':
                setSelectedInterval('1d');
                startDate = dayjs().subtract(1, 'month').format('YYYY-MM-DD');
                break;
            case '3m':
                setSelectedInterval('1d');
                startDate = dayjs().subtract(3, 'month').format('YYYY-MM-DD');
                break;
            case 'ytd':
                setSelectedInterval('1d');
                startDate = dayjs().startOf('year').format('YYYY-MM-DD');
                break;
            case '1y':
                setSelectedInterval('1d');
                startDate = dayjs().subtract(1, 'year').format('YYYY-MM-DD');
                break;
            default:
                console.log('Invalid interval');
                return;
        }
    
        setSelectedStartDate(startDate);
        setShouldFetch(true);
    };

    const handlePredictionCreate = async () => {
        setisLoadingPrediction(true);
        const userId = localStorage.getItem("userId") ? localStorage.getItem("userId")?.replace(/"/g, '') : '';
        const indicatorsString = selectedIndicators.join(",");
        const requestData: PostDataPredictionCreatePredictionParams = {
            userId: userId,
            ticker: selectedTicker || '',
            tickerType: selectedCategory,
            interval: selectedInterval,
            indicators: indicatorsString,
            starteDate: selectedStartDate,
            optimizer: optimizer,
            epochs: epochs.toString(),
            batchSize: batchSize.toString(),
            inputUnits: inputUnits.toString(),
        };

        try {
            const response = await postDataPredictionCreatePrediction(requestData);
            const responseData = response as unknown as AxiosResponse<string>;
            const predictionId = responseData.data;
            setPredictionId(predictionId);
        } catch (err) {
            console.error('Error creating prediction:', err);
        }
    };

    const handleAccordionChange = (panel: string) => (event: React.SyntheticEvent, isExpanded: boolean) => {
        setExpandedPanel(isExpanded ? panel : false);
    };

    const handleIndicatorChange = (indicatorKey: string) => {
        setSelectedIndicators((prevSelected) =>
            prevSelected.includes(indicatorKey)
                ? prevSelected.filter((key) => key !== indicatorKey)
                : [...prevSelected, indicatorKey]
        );
        setShouldFetch(true);
    };

    const updatePredictionStatus = useCallback(async (predictionId: string, intervalId: any) => {
        const request: GetDataPredictionGetPredictionStatusParams = {
            dataPredictionId: predictionId,
        };

        try {
            const response = await getDataPredictionGetPredictionStatus(request);
            const responseData = response as unknown as AxiosResponse<string>;
            setPredictionStatus(responseData.data);
            if (parseInt(responseData.data, 10) == -1) {
                clearInterval(intervalId);
                setIntervalId(null);
                //get result
                const result = await getDataPredictionGetPrediction(request);
                const resultData = result.data as unknown as DataPredictionResult;
                setPredictionResult(resultData.result)
                setisLoadingPrediction(false);
            }
        } catch (err) {
            console.error('Error fetching prediction status:', err);
        }
    }, []);

    const formatPredictionResult = (result: string) => {
        const parsedResult = JSON.parse(predictionResult);
        console.log('parsedResult: ', parsedResult);

        let relevantDate = '';
        if (selectedInterval == '1d') {
            relevantDate = 'end of next working day'
        }
        if (selectedInterval == '1h') {
            relevantDate = 'end of next working hour'
        }

        const [dateTime, value] = parsedResult;
        const [date, time] = dateTime.split('T');
        let positive = (value - lastPrice) > 0;
        const arrowClass = positive ? 'arrow-up' : 'arrow-down';
        return (
            <>
                <div>ticker: {selectedTicker},</div>
                <div>{relevantDate}, </div>
                <div>value: {value.toFixed(2)}<span className={arrowClass}>{positive ? '↗' : '↘'}</span></div>
            </>
        );
    };

    useEffect(() => {
        if (shouldFetch) {
            handleFeedData();
        }
    }, [shouldFetch]);

    useEffect(() => {
        if (predictionId && predictionStatus !== '-1') { // && intervalId == null
            const id = setInterval(() => {
                updatePredictionStatus(predictionId, id);
            }, 1000);
            setIntervalId(id);

            return () => clearInterval(id);
        }
    }, [predictionId, updatePredictionStatus]);

    return (
        <>
            <ErrorBoundary>
            <div className="page-container">
                {currentUser ? (
                    <>
                        <div style={{ display: 'flex', flexDirection: 'row'}}>
                        <div className="symbols-container" >
                            <Typography className="section-header" gutterBottom>
                                Symbols
                            </Typography>
                            {exchangeItemsData?.data?.map((item, index) => (
                                <Accordion
                                    key={index}
                                    expanded={expandedPanel === item.category}
                                    onChange={handleAccordionChange(item.category)}
                                >
                                    <AccordionSummary
                                        expandIcon={<ExpandMoreIcon />}
                                        aria-controls={`panel${index}-content`}
                                        id={`panel${index}-header`}
                                    >
                                        <Typography className="symbol-outer">{item.category}</Typography>
                                    </AccordionSummary>
                                    <AccordionDetails style={{ cursor: 'pointer', display: 'flex', flexDirection: 'column', gap: '10px' }}>
                                        {item.coveredItems
                                            .sort((a, b) => a.name.localeCompare(b.name)) // Sort covered items alphabetically
                                            .map((innerItem) => (
                                                <Typography key={innerItem.ticker} className="symbol-inner" onClick={() => handleTickerClick(innerItem.ticker, item.category)}>
                                                    {innerItem.name}
                                                </Typography>
                                            ))}
                                    </AccordionDetails>
                                </Accordion>
                            ))}
                        </div>
                        <div className="controls-container">
                            <div className="create-prediction-container">
                                <h2 className="section-header">Create Prediction</h2>
                                <FormControl fullWidth margin="normal">
                                    <InputLabel className="optimizer-label">Optimizer</InputLabel>
                                    <Select
                                        value={optimizer}
                                        onChange={(e) => setOptimizer(e.target.value as string)}
                                    >
                                        <MenuItem value="adam">Adam</MenuItem>
                                        <MenuItem value="sgd">SGD</MenuItem>

                                    </Select>
                                </FormControl>
                                <TextField
                                    label="Epochs"
                                    type="number"
                                    value={epochs}
                                    onChange={(e) => setEpochs(parseInt(e.target.value, 10))}
                                    fullWidth
                                    margin="normal"
                                />
                                <TextField
                                    label="Input Units"
                                    type="number"
                                    value={inputUnits}
                                    onChange={(e) => setInputUnits(parseInt(e.target.value, 10))}
                                    fullWidth
                                    margin="normal"
                                />
                                <TextField
                                    label="Batch Size"
                                    type="number"
                                    value={batchSize}
                                    onChange={(e) => setBatchSize(parseInt(e.target.value, 10))}
                                    fullWidth
                                    margin="normal"
                                />
                                <Button onClick={handlePredictionCreate} variant="contained" disabled={profileData?.data.isPremium == false} color="primary">
                                    Create Prediction
                                </Button> 
                                {profileData?.data.isPremium == false ? (
                                    <div className="warning-container">
                                        <h2>To create prediction, you need to get access: <br></br> 
                                        <a className="url" target="_blank" href="https://shop.fintool.org/?product=fintool-org-access">https://shop.fintool.org/?product=fintool-org-access</a>
                                        </h2>
                                    </div>
                                ) : (
                                    <>
                                    {isLoadingPrediction && profileData?.data.isPremium ? (
                                        <div className="prediction-container-large">
                                            <h2>Prediction Status:<div className="spinner"></div></h2>
                                            <h2>You are {predictionStatus} in line for model processing..</h2>
                                            <h2>Estimated time: {JSON.stringify(predictionEstimatedTime, null, 2)} seconds</h2>
                                        </div>
                                        ) : (
                                        <div>
                                            {predictionResult !== "" && (
                                                <div className="prediction-container-large">
                                                    <h2>Prediction Result:</h2>
                                                    <pre>{formatPredictionResult(predictionResult)}</pre>
                                                </div>
                                            )}
                                        </div>
                                    )}
                                    </>
                                )}
                            </div>
                        </div>
                    </div>
                    <div className="result-container"> 
                        {isLoadingPrediction && profileData?.data.isPremium ? (
                            <div className="prediction-container-small">
                                <h2>Prediction Status:<div className="spinner"></div></h2>
                                <h2>You are {predictionStatus} in line for model processing..</h2>
                                <h2>Estimated time: {JSON.stringify(predictionEstimatedTime, null, 2)} seconds</h2>
                            </div>
                            ) : (
                            <div>
                                {predictionResult !== "" && (
                                    <div className="prediction-container-small">
                                        <h2>Prediction Result:</h2>
                                        <pre>{formatPredictionResult(predictionResult)}</pre>
                                    </div>
                                )}
                            </div>
                        )}
                    </div>
                    <div className="charts-container">
                        
                        <div className="chart-controls-container">

                            <div className="chart-controls">
                                <Typography className="symbol-outer" style={{ margin: '2px', fontWeight: 'bold' }}>
                                    {selectedTicker ? `Ticker: ${selectedTicker}` : ''}
                                </Typography>
                                <Typography className="symbol-outer" style={{ margin: '2px'}}>
                                    {selectedTicker ? `Interval: ${selectedInterval}` : ''}
                                </Typography>
                            </div>

                            <div className="interval-wrapper">
                                <Typography  className="symbol-outer" style={{ cursor: 'pointer', margin: '3px', fontWeight: activeInterval === '1d' ? 'bold' : 'normal' }} onClick={() => handleIntervalClick('1d')}>1Day</Typography>
                                <Typography  className="symbol-outer" style={{ cursor: 'pointer', margin: '3px', fontWeight: activeInterval === '5d' ? 'bold' : 'normal' }} onClick={() => handleIntervalClick('5d')}>5D</Typography>
                                <Typography  className="symbol-outer" style={{ cursor: 'pointer', margin: '3px', fontWeight: activeInterval === '1m' ? 'bold' : 'normal' }} onClick={() => handleIntervalClick('1m')}>1M</Typography>
                                <Typography  className="symbol-outer" style={{ cursor: 'pointer', margin: '3px', fontWeight: activeInterval === '3m' ? 'bold' : 'normal' }} onClick={() => handleIntervalClick('3m')}>3M</Typography>
                                <Typography  className="symbol-outer" style={{ cursor: 'pointer', margin: '3px', fontWeight: activeInterval === 'ytd' ? 'bold' : 'normal' }} onClick={() => handleIntervalClick('ytd')}>YTD</Typography>
                                <Typography  className="symbol-outer" style={{ cursor: 'pointer', margin: '3px', fontWeight: activeInterval === '1y' ? 'bold' : 'normal' }} onClick={() => handleIntervalClick('1y')}>1Y</Typography>
                            </div>
                            
                        </div>
                            
                        <div className="charts-wrapper" >
                            <div className="chart-container" >
                                <h3 className="">Price:</h3>
                                <ReactApexChart className="chart" options={chartData} series={chartData.series || []} type="candlestick" />
                            </div>
                            <div className="chart-container" >
                                {/* <h3 className="">Indicators: {selectedIndicators.length == 0 ? 'None selected' : ''}</h3> */}
                                <Typography className="section-header" gutterBottom style={{ marginTop: '20px' }}>
                                    Indicators
                                </Typography>
                                <Accordion
                                    expanded={expandedPanel === 'indicators'}
                                    onChange={handleAccordionChange('indicators')}
                                >
                                    <AccordionSummary
                                        expandIcon={<ExpandMoreIcon />}
                                        aria-controls="panel-indicators-content"
                                        id="panel-indicators-header"
                                    >
                                        <Typography>Indicators</Typography>
                                    </AccordionSummary>
                                    <AccordionDetails style={{ cursor: 'pointer', display: 'flex', flexDirection: 'column', gap: '10px' }}>
                                        {indicatorsData?.data
                                            ?.sort((a, b) => a.indicatorName.localeCompare(b.indicatorName)) // Sort indicators alphabetically
                                            .map((indicator) => (
                                            <FormControlLabel
                                                key={indicator.indicatorKey}
                                                control={
                                                    <Checkbox
                                                        checked={selectedIndicators.includes(indicator.indicatorKey)}
                                                        onChange={() => handleIndicatorChange(indicator.indicatorKey)}
                                                        name={indicator.indicatorName}
                                                    />
                                                }
                                                label={<Typography className="symbol-inner ">{indicator.indicatorName}</Typography>}
                                            />
                                        ))}
                                    </AccordionDetails>
                                </Accordion>
                                <ReactApexChart className="chart" options={indicatorsChartData} series={indicatorsChartData.series || []} type="line" />
                            </div>
                            {/* <div className="chart-container" >
                                <ReactApexChart className="chart" options={mixedChartData} series={mixedChartData.series || []} type="line"  />
                            </div> */}
                            
                        </div>
                    </div>
                    </>
                ) : (
                    <div className='p-info'>
                        <div className='p1-info'>You don't have access.</div>
                        <Button
                            type="button"
                            variant="contained"
                            color="primary">
                            <Link className="link" to="/login">Sign In</Link>
                        </Button>
                        or
                        <Button
                            type="button"
                            variant="contained"
                            color="primary">
                            <Link className="link" to="/login">Create Account</Link>
                        </Button>
                        <div className="home-vid">
                            <iframe
                                width="100%"
                                height="400px"
                                src="https://www.youtube.com/embed/mX5wRjE0m_w" 
                                title="YouTube video player"
                                frameBorder="0"
                                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                                allowFullScreen
                            ></iframe>
                        </div>
                    </div>
                )}
            </div>
            </ErrorBoundary>
        </>
    );
};

export default Prediction;
